import { user } from "@/services/requests";
import { AxiosError } from "axios";
import { useRouter } from "next/router";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import { useMutation } from "react-query";

function useUserLogin() {
  const { push } = useRouter();

  const { locale_path } = parseCookies();

  const {
    mutate: postLogin,
    isLoading,
    error,
  } = useMutation(
    (params: {
      phone: string;
      password: string;
      remember_me: number;
      uuid: null | string;
    }) => user.postLogin(params),
    {
      onSuccess: (data) => {
        setCookie(null, "auth", data.data.access_token, {
          maxAge: 30 * 24 * 60 * 60,
          path: "/",
        });

        push({
          pathname: `/${locale_path}/account/orders`,
        });

        destroyCookie(null, "uuid", { path: "/" });
      },
    }
  );

  return { postLogin, isLoading, error: error as AxiosError };
}

export default useUserLogin;
