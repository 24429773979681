import { commons } from "@/services/requests";
import { useQuery } from "react-query";

export default function useSettingsAll() {
  const { data: settings, isLoading: isLoadingSettings } = useQuery(
    ["SETTINGS_ALL"],
    commons.getSettings
  );

  return { settings, isLoadingSettings };
}
