import { baseURL } from "@/services/api";
import { GetServerSideProps } from "next";
import Head from "next/head";
import Link from "next/link";
import styled from "styled-components";
import EyeClosed from "@/assets/img/eyeClosed.svg";
import EyeOpen from "@/assets/img/eyeOpen.svg";
import useUserLogin from "@/hooks/user/useUserLogin";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import nookies, { parseCookies } from "nookies";
import ErrorModal from "@/components/Ui/Pages/ErrorModal/ErrorModal";
import { t } from "@/helper/helper";
import useSettingsAll from "@/hooks/common/useSettingsAll";

interface Props {
  locale: string;
}

export default function Login({ locale }: Props) {
  const { uuid } = parseCookies();

  const [params, setParams] = useState<{
    phone: string;
    password: string;
    remember_me: number;
    uuid: string | null;
  }>({
    phone: "",
    password: "",
    remember_me: 0,
    uuid,
  });

  const { postLogin, isLoading, error } = useUserLogin();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    if (name === "phone") {
      setParams((prev) => ({
        ...prev,
        [name]: "994" + value,
      }));
    } else {
      setParams((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!isLoading) postLogin(params);
  };

  const [isError, setIsError] = useState<boolean>(false);

  const [type, setType] = useState<string>("password");

  useEffect(() => {
    /* @ts-ignore */
    if (error?.response?.data?.errors && error?.response?.data?.errors[0]) {
      setIsError(true);
    }
  }, [error]);

  const { settings, isLoadingSettings } = useSettingsAll();

  return (
    <>
      <Head>
        <title>{"Promusic.az " + t("login", locale)}</title>
      </Head>
      <Wrapper>
        <div className="login">
          {/* @ts-ignore */}
          {isError && error?.response?.data?.errors ? (
            /* @ts-ignore */
            <ErrorModal
              setClose={setIsError}
              /* @ts-ignore */
              content={error?.response?.data?.errors[0]}
            />
          ) : (
            <></>
          )}
          <div className="login-img-container">
            {!isLoadingSettings && (
              <img src={settings?.data.login_img} alt="Login" />
            )}
          </div>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="header">
              <h1 className="title">{t("login", locale)}</h1>
              <h3 className="subtitle">{t("loginFor", locale)}</h3>
              <div className="google-facebook">
                <a href="https://api.promusic.az/auth/google">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.7666 9.6498H22.8V9.6H12V14.4H18.7818C17.7924 17.1942 15.1338 19.2 12 19.2C8.0238 19.2 4.8 15.9762 4.8 12C4.8 8.0238 8.0238 4.8 12 4.8C13.8354 4.8 15.5052 5.4924 16.7766 6.6234L20.1708 3.2292C18.0276 1.2318 15.1608 0 12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 11.1954 23.9172 10.41 23.7666 9.6498Z"
                      fill="#FFC107"
                    />
                    <path
                      d="M12.0014 23.9992C15.101 23.9992 17.9174 22.813 20.0468 20.884L16.3328 17.7412C15.0875 18.6883 13.5658 19.2005 12.0014 19.1992C8.88016 19.1992 6.22996 17.209 5.23156 14.4316L1.31836 17.4466C3.30436 21.3328 7.33756 23.9992 12.0014 23.9992Z"
                      fill="#4CAF50"
                    />
                    <path
                      d="M23.7666 9.65039H22.8V9.60059H12V14.4006H18.7818C18.3085 15.7304 17.456 16.8925 16.3296 17.7432L16.3314 17.742L20.0454 20.8848C19.7826 21.1236 24 18.0006 24 12.0006C24 11.196 23.9172 10.4106 23.7666 9.65039Z"
                      fill="#1976D2"
                    />
                    <path
                      d="M1.38281 6.4146L5.32541 9.306C6.39221 6.6648 8.97581 4.8 11.9992 4.8C13.8346 4.8 15.5044 5.4924 16.7758 6.6234L20.17 3.2292C18.0268 1.2318 15.16 0 11.9992 0C7.39001 0 3.39281 2.6022 1.38281 6.4146Z"
                      fill="#FF3D00"
                    />
                  </svg>
                </a>
                <a href="https://api.promusic.az/auth/facebook">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 12.0731C24 18.0979 19.6125 23.0945 13.875 24V15.563H16.6711L17.2031 12.0731H13.875V9.80939C13.875 8.85439 14.3391 7.92297 15.8297 7.92297H17.3438V4.95186C17.3438 4.95186 15.9703 4.71605 14.6578 4.71605C11.9156 4.71605 10.125 6.3879 10.125 9.41324V12.0731H7.07813V15.563H10.125V24C4.3875 23.0945 0 18.0979 0 12.0731C0 5.4046 5.37188 0 12 0C18.6281 0 24 5.4046 24 12.0731Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="form-control loginFormControl">
              <label htmlFor="phone">{t("mobilePhone", locale)}</label>
              <span className="prefix">+994</span>
              <input
                spellCheck="false"
                type="number"
                name="phone"
                id="phone"
                placeholder="50 000 00 00"
                onChange={handleChange}
              />

              <small>
                {/* @ts-ignore */}
                {error?.response?.data?.errors?.phone
                  ? // @ts-ignore
                    error?.response?.data?.errors?.phone[0]
                  : "\u00A0"}
              </small>
            </div>
            <div className="form-control">
              <label className="password-label" htmlFor="currentPassword">
                <span>{t("password", locale)}</span>
                <Link href={`${locale}/forgot-password`}>
                  {t("forgotPassword", locale)}
                </Link>
              </label>
              <div className="password-container">
                <input
                  spellCheck="false"
                  type={type}
                  className="currentPassword"
                  name="password"
                  id="currentPassword"
                  onChange={handleChange}
                />
                <span
                  className="eye"
                  onClick={(e) => {
                    type === "password" ? setType("text") : setType("password");
                  }}
                >
                  <img
                    src={type === "password" ? EyeOpen.src : EyeClosed.src}
                    alt="eye icon"
                  />
                </span>
              </div>

              <small>
                {/* @ts-ignore */}
                {error?.response?.data?.errors?.password
                  ? // @ts-ignore
                    error?.response?.data?.errors?.password[0]
                  : "\u00A0"}
              </small>
            </div>
            <button className="submit-btn" onClick={handleSubmit}>
              {t("startShopping", locale)}
            </button>

            <div className="bottom-login-class">
              {t("dontHaveAccount", locale)}{" "}
              <Link href={`/${locale}/register`}>{t("register", locale)}</Link>
            </div>
          </form>
        </div>
      </Wrapper>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { locale } = ctx;
  const { auth } = nookies.get(ctx);

  try {
    if (auth) {
      await fetch(baseURL + "/profile", {
        headers: {
          "x-localization": locale ? locale : "az",
          authorization: "Bearer " + auth,
        },
      });

      return {
        redirect: {
          permanent: false,
          destination: `/${locale}/account/orders`,
        },
      };
    }

    return {
      props: {
        locale,
      },
    };
  } catch (error: any) {
    if (error.response.status === 401) {
      nookies.destroy(ctx, "auth", { path: "/" });

      return {
        redirect: {
          permanent: false,
          destination: `/${locale}/login`,
        },
      };
    }

    return {
      redirect: {
        permanent: false,
        destination: `/${locale}`,
      },
    };
  }
};

const Wrapper = styled.div`
  .login {
    width: 1289px;
    // margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 0 2.4rem;
    margin: 4.8rem auto;
    padding-bottom: 5.6rem;

    @media screen and (max-width: 1199px) {
      width: 100%;
      padding: 0 1.6rem;
    }

    .google-facebook {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1.2rem 0 2.4rem;

      a {
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        box-sizing: border-box;
        border-radius: 0.4rem;

        &:first-child {
          border: 0.1rem solid rgba(5, 6, 30, 0.07);
          margin-right: 2.4rem;
        }

        &:last-child {
          background: #1778f2;
        }
      }
    }

    &-img-container {
      grid-column: 1/4;
      width: 100%;
      height: 500px;
      background: #c4c4c4;
      border-radius: 12px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .login-form {
      grid-column: 5/8;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .header {
        padding-bottom: 2.4rem;

        .title {
          font-style: normal;
          font-weight: 500;
          font-size: 3.6rem;
          line-height: 100%;
          color: #3a4155;
          margin-bottom: 12px;
        }

        .subtitle {
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 150%;
          color: #989eaf;
          margin-bottom: 1.2rem;
        }
      }

      .form-control {
        &.loginFormControl {
          position: relative;

          #phone {
            margin-top: -20px;
          }

          .prefix {
            top: 22px;
          }
        }

        .prefix {
          width: 6rem;
          position: relative;
          margin-bottom: -3.9rem;
          padding-left: 2.4rem;
          font-style: normal;
          font-weight: normal;
          font-size: 1.8rem;
          line-height: 100%;
          color: #989eaf;
          top: 40px;

          & + input {
            padding-left: 7rem;
          }
        }

        input {
          border: 1px solid #3a4155;
          &::placeholder {
            color: #989eaf;
          }
        }

        label {
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 100%;
          color: #3a4155;

          &::placeholder {
            font-size: 1.6rem;
            color: #989eaf;
          }
        }
      }

      .submit-btn {
        height: 6rem;
        width: 100%;
        background: #444444;
        border-radius: 6px;
        font-style: normal;
        font-weight: normal;
        font-size: 1.8rem;
        line-height: 100%;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
      }
    }

    .bottom-login-class {
      margin-top: 78px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 100%;
      color: #1b1c1f;

      a {
        color: #ff4c4c;
        margin-left: 6px;
      }
    }

    @media screen and (max-width: 991.9px) {
      .bottom-login-class {
        margin-top: 48px;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 100%;
        color: #1b1c1f;

        a {
          color: #ff4c4c;
          margin-left: 6px;
        }
      }

      &-img-container {
        display: none;
      }

      .login-form {
        grid-column: 1/9;
      }

      .header {
        .title {
          font-size: 36px;
        }

        .subtitle {
          font-size: 16px;
        }
      }
    }

    @media screen and (max-width: 767.9px) {
      padding: 39px 16px 111px;
    }
  }
`;
